import { FaFacebookF, FaSpotify, FaVimeoV } from "react-icons/fa";

function Footer() {
  return (
    <footer className="flex flex-col gap-5 justify-center items-center h-40  ">
      <div className=" flex gap-5">
        <a href="https://www.facebook.com/philippe.foch/?locale=fr_FR">
          <FaFacebookF color="white" fontSize="1.3em" />
        </a>
        <a href="https://open.spotify.com/intl-fr/artist/0WdOUNzg9kR6ie2e2Sd3xe">
          <FaSpotify color="white" fontSize="1.3em" />
        </a>
        <a href="https://vimeo.com/channels/philippefoch">
          <FaVimeoV color="white" fontSize="1.3em" />
        </a>
      </div>

      <p className=" text-white opacity-50">© 2024 Philippe Foch</p>
    </footer>
  );
}

export default Footer;
