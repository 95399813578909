import React from "react";
import { motion } from "framer-motion";

const Button = (text) => {
  return (
    <motion.button
      whileHover={{ scale: 1.06, opacity: 0.8 }}
      whileTap={{ opacity: 0.5 }}
      whileFocus={{ opacity: 0.5 }}
      className=" border rounded-md border-inherit text-white pr-5 pl-5 pt-2 pb-2 mt-10"
    >
      {text}
    </motion.button>
  );
};

export default Button;
