import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import Agenda from "./components/Agenda/Agenda";
import Contact from "./components/Contact/Contact";
import Photos from "./components/Photos/Photos";
import SolosEtFormations from "./components/Solos_et_Formations/SolosEtFormations";
import Son from "./components/Son/Son";
import Spectacles from "./components/Spectacles/Spectacles";
import Biographie from "./components/Biographie/Biographie";
import Videos from "./components/Videos/Videos";

import ScrollToTop from "./components/ScrollTopTop";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/Agenda" element={<Agenda />} />
          <Route path="/Videos" element={<Videos />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Photos" element={<Photos />} />
          <Route path="/Bio" element={<Biographie />} />
          <Route path="/SolosEtCreations" element={<SolosEtFormations />} />
          <Route path="/Son" element={<Son />} />
          <Route path="/SpectaclesEtGroupes" element={<Spectacles />} />
          <Route path="/Biographie" element={<Biographie />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
