import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Header from "../Header/Header";
import { motion } from "framer-motion";
import Custombutton from "../button/Button";

import img1 from "../../assets/PHOTOS/Foch Tambour.jpeg";
import Footer from "../Footer/Footer";

function ContactForm() {
  const [state, handleSubmit] = useForm("xkgwwwjg");
  return (
    <div className=" relative h-screen font-poppins text-white ">
      <Header />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className=" flex flex-col lg:flex-row lg:min-h-screen justify-center gap-28 pt-24 pr-5 pl-5 pb-20 lg:pt-52"
      >
        <img
          className=" rounded-xl lg:w-1/3 w-1/3 h-1/3  lg:h-full  "
          src={img1}
          alt="Philippe"
        />
        <div className=" flex flex-col gap-16">
          <div className=" flex flex-col gap-3">
            <h1 className="text-4xl font-light">Contact</h1>
            <p className="font-light">
              Pour toute demande de renseignement, merci de remplir le
              formulaire <br />
              ci-dessous ou me contacter directement :
            </p>
            <p className="font-light">
              <a
                href="mailto:philippe.foch@wanadoo.fr"
                className="text-[#A3883C]"
              >
                philippe.foch@wanadoo.fr
              </a>
            </p>
            <p className="font-light">
              <a href="tel:0616189103" className="text-[#A3883C]">
                06 16 18 91 03
              </a>
            </p>
            <p className="font-light text-[#A3883C]">Vincennes, France</p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-start gap-10 font-light  text-xl"
          >
            <input
              className="appearance-none bg-transparent focus:outline-none border-b-2 w-full pb-3 pl-1"
              type="text"
              placeholder="Nom"
              aria-label="Full name"
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <input
              className=" bg-transparent focus:outline-none border-b-2 w-full pb-3 pl-1"
              type="email"
              placeholder="Email"
              aria-label="Full name"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              rows="4"
              cols="50"
              class=" bg-transparent focus:outline-none border-b-2 w-full pb-3 pl-1 "
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            <button type="submit" disabled={state.submitting}>
              {Custombutton("Envoyer")}
            </button>
            {state.succeeded && <p>Enregistré !</p>}
          </form>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default ContactForm;
