import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import custombutton from "../button/Button";
import YouTube from "@u-wave/react-youtube";
import Vimeo from "@u-wave/react-vimeo";

function Zone02() {
  return (
    <motion.div
      id="zone02"
      className="flex flex-col items-center font-poppins bg-[#101010] h-full pt-20 pl-5 pr-5 pb-20 lg:p-20"
    >
      <div className=" flex justify-center pb-8">
        <h1 className="text-[#A3883C] text-5xl lg:text-6xl font-thin tracking-widest ">
          VIDEOS
        </h1>
      </div>
      <div className="lg:flex lg:gap-12 w-full  font-thin text-3xl ">
        <div className=" w-full pb-3">
          <h1 className=" text-white lg:pb-3">PIERRES</h1>

          <YouTube video="9ASeXmcGmRE" className=" w-full h-[90%] " />
        </div>
        <div className=" w-full  pb-3">
          <h1 className=" text-white lg:pb-3">FER</h1>
          <Vimeo video="201710720" responsive />
        </div>
        <div className=" w-full  pb-3">
          <h1 className=" text-white lg:pb-3">BODHRAN</h1>
          <Vimeo video="263580526" responsive />
        </div>
      </div>
      <div className=" pt-7">
        <Link to="/Videos">{custombutton("Voir plus de vidéos")}</Link>
      </div>
    </motion.div>
  );
}

export default Zone02;
