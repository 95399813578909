import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { motion } from "framer-motion";

import "react-loading-skeleton/dist/skeleton.css";
import "react-h5-audio-player/src/styles.scss";

const Son = () => {
  const Son = [
    {
      id: "HERZ",
      src: "https://open.spotify.com/embed/album/7qsBI2gN91klpIfGEQDLdW?utm_source=generator&theme=0",
    },
    {
      id: "Live au GRM",
      src: "https://open.spotify.com/embed/album/4s1u70Gde88GsoVufklOyK?utm_source=generator&theme=0",
    },
    {
      id: "Secret",
      src: "https://open.spotify.com/embed/album/5KWnry0VJMBYz3Qd1pY1Vh?utm_source=generator&theme=0",
    },
    {
      id: "Taarang",
      src: "https://open.spotify.com/embed/album/4D2El0Vn5JiXLKDJw1Hw54?utm_source=generator&theme=0",
    },

    {
      id: "Les voyageurs de l'espace",
      src: "https://open.spotify.com/embed/album/6aExZ5EriohQDd1LYwYVoO?utm_source=generator&theme=0",
    },
    {
      id: "Les amants de Juliette s'électrolysent",
      src: "https://open.spotify.com/embed/album/6QrvfKk3n0WUa0IUCsC5y3?utm_source=generator&theme=0",
    },
    {
      id: `Adam/Delbecq/Foch`,
      src: "https://open.spotify.com/embed/album/4UvO7hgpfncIp7xOWkgbSu?utm_source=generator&theme=0",
    },
    {
      id: " Les amants de Juliette ",
      src: "https://open.spotify.com/embed/album/6XmXxkSnPaaA7rRA5KAQeN?utm_source=generator&theme=0",
    },
    {
      id: "Les Amants De Juliette #4",
      src: "https://open.spotify.com/embed/album/7coz4utvgehgnuq8VnHmZ9?utm_source=generator&theme=0",
    },
    {
      id: "Les Amants De Juliette",
      src: "https://open.spotify.com/embed/album/1Mmpmo1Q5IuA8vWToXUfHP?utm_source=generator&theme=0",
    },
  ];

  return (
    <div>
      <Header />

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className=" flex justify-center lg:pt-52 pt-24 pb-20  "
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full lg:pl-20 lg:pr-20 pl-5 pr-5">
          {Son.map((son) => (
            <iframe
              title={son.id}
              style={{ marginBottom: "20px" }}
              src={son.src}
              width="100%"
              height="450"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          ))}
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Son;
