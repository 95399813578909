const Description = () => {
  return (
    <div>
      <p>
        Sophie Agnel et Philippe Foch aiment travailler la matière sonore
        Philippe Foch aux percussions , intègre à son set de peaux des matériaux
        bruts (pierres, métaux, végétaux ).
        <br />
        <br />
        Sophie Agnel au piano et aussi au cordophone, instrument à corde
        électroacoustique hybride qu’elle a fait construire, fait résonner tout
        un ensemble d’objets. 
        <br />
        <br />
        Ensemble il mettent    l’espace    en vibration    et invitent le public
        à cheminer librement dans ce territoire sensible.
        <br />
        <br />
        <br />
        SOPHIE AGNEL : CORDOPHONE
        <br />
        JEAN GABRIEL VALOT : DISPOSITIF LUMIÈRE
        <br />
        PHILIPPE FOCH : PERCUSSIONS
      </p>
    </div>
  );
};

export default Description;
