import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";

function Zone01() {
  return (
    <div className=" bg-zone01-responsive-bg md:bg-zone01-bg lg:bg-fixed bg-cover bg-no-repeat bg-center md:bg-[center_top_-10rem] h-screen font-poppins">
      <motion.div className="flex justify-center items-center h-full w-full lg:p-20 ">
        <motion.div className="flex flex-col items-center   text-white font-thin text-4xl  pb-20">
          <motion.p className="  text-white font-thin text-5xl opacity-75 lg:opacity-50  md:text-[7rem]">
            PHILIPPE FOCH
          </motion.p>
          <motion.p className="text-white font-thin text-4xl opacity-75 lg:opacity-50 pb-14">
            Percussions
          </motion.p>
          <Link smooth to="#zone02">
            <motion.svg
              initial={{ y: 0 }}
              animate={{ y: 6 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              whileHover={{ scale: 1.2 }}
              className="cursor-pointer"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 472 606"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M469.333 369.667L236 603M236 603L2.66663 369.667M236 603V3"
                stroke="white"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </motion.svg>
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Zone01;
