import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import img from "../../assets/PHOTOS/mains img 13.jpg";
import { motion } from "framer-motion";

function Biographie() {
  return (
    <div>
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="2xl:flex 2xl:justify-center gap-28 pt-24  lg:pt-52 pr-5 pl-5 lg:pl-20 lg:pr-20 pb-20 "
      >
        <div className=" flex flex-col 2xl:w-1/3 h-1/3 gap-10">
          <img className="    rounded-xl pb-5" src={img} alt="Philippe Foch" />
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className=" text-white font-light lg:text-xl italic"
          >
            « Mû par un désir vivace de rencontre et d’exploration, qui non
            seulement ne s’émousse pas mais semble au contraire s’aiguiser à
            mesure que le temps passe, Philippe Foch, batteur de formation,
            gravite depuis 30 ans à l’intérieur d’un territoire sonore
            intensément mouvant et, rétif à toute forme de routine ou de
            statuquo, ne cesse de remettre en jeu ses acquis et de réinventer
            son langage musical. Ce langage, dont un riche attirail percussif
            constitue le cœur battant, frappe d’emblée par sa tonicité rythmique
            et par sa vitalité organique : un langage ruminé longuement mais
            tout entier jaillissant dans l’ici et maintenant. » <br />
            <br />
            -Jerome Provencal     Mouvement <br />
            <br />
          </motion.span>
        </div>

        <div className=" 2xl:w-2/5  text-white font-poppins font-extralight lg:text-xl ">
          <h1 className="text-4xl mb-4 font-normal">Biographie</h1>
          <p className="mb-6">
            Philippe Foch gravite depuis 30 ans à l’intérieur d’un riche
            territoire sonore, de la batterie aux percussions asiatiques,
            indiennes (il a étudié les tablas auprès du pandit Shankar Ghosh),
            aux matériaux bruts (phonolites, métaux, végétaux) jusqu’au
            traitement électronique. Associant les musiques improvisées,
            expérimentales, indienne et ses expériences théâtrales ou
            performatives, son jeu et son écriture développent une riche palette
            de jeu traditionnel, de sons concrets ou électroniques, ainsi qu’un
            vrai investissement physique et un goût du plateau.
          </p>

          <p className="mb-6">
            Il a collaboré avec de nombreux artistes issus des musiques
            improvisées et électroniques : Erwan Keravec, Sophie Agnel, Simon
            Henocq, Kasper T. Toeplitz, Akosh S, Benoit Delbecq, Mathias
            Delplanque, Didier Petit, Claudia Solal, Eryck Abecassis.
          </p>

          <p className="mb-6">
            Il a développé son langage et son rapport à la matière à travers
            plusieurs solos :
          </p>
          <ul className="list-disc list-inside mb-6">
            <li>
              <strong>TAARANG</strong> - Ensemble de 15 tablas et électronique
              (CD avec le label Signature de Radio France).
            </li>
            <li>
              <strong>LAAND</strong> - Solo autour du lithophone (ensemble de
              pierres sonnantes) et électronique.
            </li>
            <li>
              <strong>METAL MEMOIRE</strong> - Ensemble de plaques de métal,
              film fait main, électronique.
            </li>
            <li>
              <strong>KERNEL</strong> - Solo sur la naissance du son, pour la
              première enfance.
            </li>
          </ul>

          <p className="mb-6">
            MÉTAL MÉMOIRE (pièce pour feuilles de métal et film projeté) et
            LUMENS (trio avec Sophie Agnel au cordophone et Jean-Gabriel Valot à
            la lumière) sont les deux premières pièces d’un tryptique autour de
            la lumière dont ASTER est le troisième volet.
          </p>
          <h2 className="text-3xl font-normal mb-4">
            Collaborations Artistiques
          </h2>
          <p className="mb-6">
            Il compose et collabore régulièrement pour le théâtre (François
            Cervantes, Catherine Germain, Olivier Martin Salvan), le cirque
            (Mathurin Bolze, Jeanne Mordoj, Cie Anomalie, La Meute), la danse
            (Sophiatou Kossoko, Myriam Gourfink, Volmir Cordeiro, Cie LINGA,
            Sarath Amaratsingam, Hella Fatoumi) ainsi que des écrivains
            (Raharimanana et Claudine Galea).
          </p>
          <h2 className="text-3xl font-normal mb-4">Dernières Créations</h2>
          <ul className="list-disc list-inside mb-6">
            <li>
              <strong>JAKLIN</strong> - Duo avec Olivier Martin Salvan, création
              2019-20.
            </li>
            <li>
              <strong>MÉTROPOLE</strong> - Duo avec Volmir Cordeiro, création
              2021.
            </li>
            <li>
              <strong>MÉTAL MÉMOIRE</strong> - Solo, création 2021.
            </li>
            <li>
              <strong>LUMENS</strong> - Avec Sophie Agnel et Jean-Gabriel Valot,
              création 2022.
            </li>
            <li>
              <strong>SEMA</strong> - Avec Mathias Delplanque pour la compagnie
              LINGA Lausanne CH, création 2023.
            </li>
            <li>
              <strong>ASTER</strong> - Solo pour tambour et éléments lumineux,
              création 2024.
            </li>
          </ul>
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">
              Discographie Sélective
            </h2>
            <ul className=" list-disc list-inside">
              <li>
                <strong>AKOSH S UNIT</strong>:
                <ul className="list-disc list-inside ml-3">
                  <li>1992 PANNONIA EMP records</li>
                  <li>1994 ASILE. Auto prod</li>
                  <li>1998 IMAFA Barclays & universal</li>
                  <li>1998 OMEKO Barclays & universal</li>
                  <li>1999 ELETTER Barclays & universal</li>
                  <li>2000 VETEK Barclays & universal</li>
                  <li>2001 KEBELEN Barclays & universal</li>
                  <li>2002 LENNE Barclays & universal</li>
                </ul>
              </li>
              <li>
                <strong>LES AMANTS DE JULIETTE</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>1994 ADAM DELBECQ FOCH Quoi de neuf docteur</li>
                  <li>1997 #2 Quoi de neuf docteur</li>
                  <li>2001 #3 Quoi de neuf docteur</li>
                  <li>2005 #4 Quoi de neuf docteur</li>
                  <li>2008 avec MAJID BEKKAS quoi de neuf docteur</li>
                  <li>2015 Les Amants s’électrolysent</li>
                </ul>
              </li>
              <li>
                <strong>OMAR SOSA</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>2000 MULATOS Ota Records</li>
                </ul>
              </li>
              <li>
                <strong>NICOLAS GENEST</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>1997 HATI La Lichère</li>
                  <li>2012 HATI Plus loin Music</li>
                </ul>
              </li>
              <li>
                <strong>SOLO</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>2014 TAARANG Signature Radio France</li>
                </ul>
              </li>
              <li>
                <strong>FOCH - DELPANQUE</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>2015 SECRET Parenthèse record </li>
                  <li>2016 LIVE AU GRM Parenthèse record</li>
                  <li>2024 AMSOMIA Signature Radio France</li>
                </ul>
              </li>
              <li>
                <strong>DIDIER PETIT - CLAUDIA SOLAL - PHILIPPE FOCH</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>2016 LES VOYAGEURS DE L'ESPACE Buda records</li>
                </ul>
              </li>
              <li>
                <strong>HENOCQ FOCH</strong>:
                <ul className="list-disc list-inside ml-5">
                  <li>2022 HERZ coax records</li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default Biographie;
