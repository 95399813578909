import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import Blind from "../../assets/spectacles/Blind.png";
import equilibre from "../../assets/spectacles/equilibre.png";
import jaklin from "../../assets/spectacles/Jacqueline.jpeg";
import metropole from "../../assets/spectacles/metropole.png";
import Paysages from "../../assets/spectacles/propagation.jpeg";
import sema from "../../assets/spectacles/sema.png";
import herz from "../../assets/imagesSolo/herz.jpeg";
import tamboursiamoises from "../../assets/spectacles/tamboursiamoises.jpeg";

const Spectacles = () => {
  const [spectacles, setSpectacles] = useState(null);
  const [isRotated, setIsRotated] = useState(false);

  const shows = [
    {
      id: "TAMBOUR SIAMOISES",
      title: "TAMBOUR SIAMOISES",
      image: tamboursiamoises,
      lineup: `ERYCK ABECASSIS : GUITARE DOUBLE MANCHE , ANALOGIC SYNTH
PHILIPPE FOCH : PERCUSSIONS , PIERRES , METAUX`,
    },
    {
      id: "HERZ",
      title: "HERZ",
      image: herz,
      lineup: `PHILIPPE FOCH : PERCUSSIONS , ELECTRONIQUE , 
      SIMON HENOCQ : ELECTRONIQUE `,
    },
    {
      id: "SEMA",
      title: "SEMA",
      image: sema,
      lineup: `Katarzyna Gdaniec et Marco Cantalupo : Choregraphie, conception .
Mathias Delplanque : Electronique , composition 
Philippe Foch : Percussions, composition
Martin Angiuli, Enzo Blond, Bonni Bogya, Aude-Marie Bouchard, András Engelmann, Lia Ujčič, Csaba Varga, Cindy Villemin : Danse `,
    },
    {
      id: "L'EQUILIBRE DE LA BICYCLETTE",
      title: "L'EQUILIBRE DE LA BICYCLETTE",
      image: equilibre,
      lineup: `Sarath Amarasingam : conception , danse
Tayeb Benamara : danse
Guillaume Cursio : danse
Philippe Foch : percussions, électronique`,
    },
    {
      id: "PAYSAGES DE PROPAGATION",
      title: "PAYSAGES DE PROPAGATION",
      image: Paysages,
      lineup: `CHRISTIAN SEBILLE : CONCEPTION, ELECTRONIQUE, TEMPS RÉEL
PHILIPPE FOCH : DISPOSITIF DE PIÈCE DE VERRe
Damien Ripoll
régisseur général, informatique et son`,
    },

    {
      id: "METROPOLE",
      title: "METROPOLE",
      image: metropole,
      lineup: `Volmir Cordeiro : conception , danse
Philippe Foch : percussions
Aria de la Celle: son`,
    },
    {
      id: "BLIND",
      title: "BLIND",
      image: Blind,
      lineup: `Erwan Keravec : Cornemuse , conception
Helene Labbarrière : Contrebasse
Raphael Quenehen : Souffles
Kenan Trevien : Son , spatialisation
Philippe Foch : Percussions`,
    },
    {
      id: "JAKLIN",
      title: "JAKLIN",
      image: jaklin,
      lineup: `Olivier Martin Salvan : conception , jeu
Philippe Foch : composition, percussions
Maxime Lance : son, traitement`,
    },
  ];

  const handleClick = (id) => {
    setSpectacles(spectacles === id ? null : id);
    setIsRotated(!isRotated);
  };

  return (
    <div className=" bg-spectacle-responsive-bg lg:bg-spectacle-bg bg-cover min-h-screen bg-fixed  bg-no-repeat    font-poppins">
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex flex-col justify-between min-h-screen lg:pl-20 lg:pr-20 lg:pt-52 pt-24 pl-5 pr-5"
      >
        {shows.map((show) => (
          <div key={show.id}>
            <div className="flex justify-between lg:justify-normal lg:gap-10  text-white text-3xl font-extralight pb-10">
              <p>{show.title}</p>

              <motion.svg
                onClick={() => handleClick(show.id)}
                className="cursor-pointer"
                fill="currentColor"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                animate={{ rotate: spectacles === show.id ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                />
              </motion.svg>
            </div>
            <AnimatePresence mode="popLayout">
              {spectacles === show.id && (
                <motion.div
                  layout
                  initial={{ y: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ y: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className=" pb-20"
                >
                  <motion.img
                    className="lg:w-1/3 "
                    src={show.image}
                    alt={show.title}
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0 }}
                  />
                  <p
                    className="text-white font-extralight text-xl mt-4"
                    dangerouslySetInnerHTML={{
                      __html: show.lineup.replace(/\n/g, "<br>"),
                    }}
                  ></p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </motion.div>
      <Footer />
    </div>
  );
};

export default Spectacles;
