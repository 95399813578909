import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";
import { useState } from "react";
import photos from "../../data/image";
import { motion } from "framer-motion";

const Photos = () => {
  const [index, setIndex] = useState(-1);

  return (
    <div>
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className=" pt-24 pr-5 pl-5 lg:pl-20 lg:pr-20 lg:pt-52 pb-20"
      >
        <PhotoAlbum
          layout="rows"
          photos={photos}
          onClick={({ index: current }) => setIndex(current)}
        />
        ;
        <Lightbox
          index={index}
          open={index >= 0}
          close={() => setIndex(-1)}
          slides={photos.map((photo) => ({
            src: photo.src,
            alt: photo.alt,
            width: 3840,
            height: 2560,
          }))}
        />
      </motion.div>
      <Footer />
    </div>
  );
};

export default Photos;
