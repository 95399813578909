import React, { useEffect, useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import custombutton from "../button/Button";

const Zone04 = () => {
  const [spectacles, setSpectacles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSpectacles = async () => {
      try {
        const response = await axios.get(
          "https://script.googleusercontent.com/macros/echo?user_content_key=Z6N1NAjuCpl17istkqsHbwX7AK8l9-UGSZ3t-5PeIRFuDJ83A0RjT4y1ACzozaz3aCnTw79NxDdfweUPnaIwzcFgyXKBdaMNOJmA1Yb3SEsKFZqtv3DaNYcMrmhZHmUMWojr9NvTBuBLhyHCd5hHa5d92FIN44eI_yitLUqyGzbNY3mL1V5ZJYpdmpdPW0Olz85mp906E7xJRzKwF9xkpomW6916B_bfPBCE2EZh7n96wo03zGqI1eX2KnvcqMMGFZR6btdKBkCY8-paDNctvg&lib=MQZdZcCkjaheMK3is8s7LoXk8-gbNiLzS"
        );

        const data = response.data.data.slice(0, 3);

        setSpectacles(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching spectacles:", error);
      }
    };

    fetchSpectacles();
  }, []);

  return (
    <div className=" font-poppins flex flex-col gap-10  bg-zone03-responsive-bg  md:bg-zone03-bg lg:bg-fixed bg-cover  bg-no-repeat lg:w-[100%] lg:min-h-full  lg:bg-[center_top_-5rem] text-white h-full lg:p-20 pl-5 pr-5 pt-20 pb-20">
      <div className=" flex justify-center pb-8">
        <h1 className="text-white text-5xl lg:text-6xl font-thin tracking-widest ">
          AGENDA
        </h1>
      </div>
      <div className="flex flex-col items-center   gap-8">
        {loading
          ? // Show skeletons while loading
            Array(3)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="w-8/12 pb-10 border-b">
                  <div className="text-[#A3883C] text-3xl mb-6">
                    <Skeleton
                      width={"100%"}
                      height={40}
                      baseColor="#404040"
                      highlightColor="#333333"
                    />
                  </div>
                  <div className="text-lg font-light mb-8">
                    <Skeleton
                      count={3}
                      baseColor="#404040"
                      highlightColor="#333333"
                    />
                  </div>
                </div>
              ))
          : spectacles.map((spectacle, index) => (
              <div
                key={index}
                className=" lg:w-8/12 pb-10 border-b border-gray-500 w-full  "
              >
                <div className=" text-[#A3883C] text-3xl  mb-6">
                  {spectacle.Titre}
                </div>
                <div className="  text-base font-light mb-8">
                  {parse(spectacle.Description.replace(/\n/g, "<br>"))}
                </div>
                <div className=" flex justify-between text-base lg:text-3xl font-extralight  ">
                  <div>
                    {parse(spectacle.Date.replace(/\n/g, "<br>").toUpperCase())}
                  </div>
                  <div className=" w-1/2  lg:w-auto">
                    {parse(
                      spectacle.Lieux.replace(/\n/g, "<br>").toUpperCase()
                    )}
                  </div>
                </div>
              </div>
            ))}
      </div>
      <div className="flex justify-center">
        <Link to="/Agenda">{custombutton("Voir l'agenda")}</Link>
      </div>
    </div>
  );
};

export default Zone04;
