import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "@u-wave/react-youtube";

import thumbnail1 from "../../assets/thumbnails/Video1Thumbnail.png";
import thumbnail2 from "../../assets/thumbnails/Screenshot 2024-06-01 183518.png";
import thumbnail3 from "../../assets/thumbnails/Screenshot 2024-06-01 191153.png";
import thumbnail4 from "../../assets/thumbnails/Screenshot 2024-06-01 191534.png";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Zone02() {
  const [loading, setLoading] = useState(false);

  const videos = [
    {
      id: "ASTER",
      src: "2sD4utmbVjA",
      thumbnail: thumbnail3,
    },
    {
      id: "PIERRES",
      src: "9ASeXmcGmRE",
      thumbnail: thumbnail1,
    },
    {
      id: "METAL MEMOIRE",
      src: "https://vimeo.com/502315517",
      thumbnail: thumbnail4,
    },
    {
      id: "FER",
      src: "https://vimeo.com/201710720",
    },
    {
      id: "SURUWAT",
      src: "https://vimeo.com/110711700",
    },
    {
      id: "IGNIS",
      src: "https://vimeo.com/140643305",
    },
    {
      id: "BODHRAN",
      src: "https://vimeo.com/263580526",
    },
    {
      id: "LUMENS",
      src: "Ff6DdroLIc0",
      thumbnail: thumbnail2,
    },
    {
      id: "TAMBOURS SIAMOISES",
      src: "LR7FNdPAy_Y",
    },
    {
      id: "SECRET",
      src: "https://vimeo.com/235408939",
    },

    {
      id: "JARDIN",
      src: "s8pWJXVjffw",
    },
    {
      id: "HERZ",
      src: "bZrw5HVt6NU",
    },
    {
      id: "NEKTAAR",
      src: "https://vimeo.com/110662098",
    },
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className="font-poppins bg-[#101010] h-full pt-24 pr-5 pl-5 p-20 lg:pt-52 lg:pl-20 lg:pr-20 lg:pb-20"
      >
        <div className="lg:grid lg:grid-cols-3 lg:grid-flow-rows flex flex-col  justify-center gap-12 font-thin text-3xl">
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            videos.map((video) => (
              <div key={video.id}>
                <h1 className="text-white pb-3">{video.id}</h1>
                {video.src.includes("vimeo") ? (
                  <Vimeo video={video.src.split("/").pop()} responsive />
                ) : (
                  <YouTube video={video.src} className=" w-full h-[90%] " />
                )}
              </div>
            ))
          )}
        </div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default Zone02;
