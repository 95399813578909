import { useState } from "react";
import Popup from "./popup";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import metalmemoire from "../../assets/imagesSolo/VUE GENERALE 2.jpeg";
import metalmemoire2 from "../../assets/imagesSolo/ES(S7s) 200626 img 58.jpg";
import lumens from "../../assets/imagesSolo/DSCF3876.jpeg";
import laand from "../../assets/imagesSolo/IMG_6195.jpg";
import kernel from "../../assets/imagesSolo/Kernel 1.jpg";
import Taarang from "../../assets/imagesSolo/Taarang.jpg";
import aster from "../../assets/imagesSolo/plan large Phospho.jpg";
import DescriptionMetalMemoire from "./description/DescriptionMetalMemoire";
import DescriptionLumens from "./description/DescriptionLumens";
import DescriptionLaands from "./description/DescriptionLaands";
import DescriptionKernel from "./description/DescriptionKernel";
import DescriptionTaarang from "./description/DescriptionTaarang";
import DescriptionAster from "./description/DescriptionAster";

const SolosEtFormations = () => {
  const [selectedShow, setSelectedShow] = useState(null);

  const [loadedImages, setLoadedImages] = useState({});

  const closePopup = () => {
    setSelectedShow(null);
  };

  const shows = [
    {
      id: "metalMemoire",
      title: "METAL MEMOIRE",
      image: metalmemoire2,
      description: <DescriptionMetalMemoire />,
      popupImage: metalmemoire,
      popupVideo: "https://vimeo.com/502315517",
    },
    {
      id: "aster",
      title: "ASTER",
      image: aster,
      description: <DescriptionAster />,
      popupImage: aster,
      YoutubeLink: "2sD4utmbVjA",
    },
    {
      id: "laand",
      title: "LAAND",
      image: laand,
      description: <DescriptionLaands />,
      popupImage: laand,
      popupVideo: "https://vimeo.com/962745985",
    },
    {
      id: "lumens",
      title: "LUMENS",
      image: lumens,
      description: <DescriptionLumens />,
      popupImage: lumens,
      YoutubeLink: "Ff6DdroLIc0",
    },
    {
      id: "taarang",
      title: "TAARANG",
      image: Taarang,
      description: <DescriptionTaarang />,
      popupImage: Taarang,
      popupVideo: "https://vimeo.com/110711700",
    },
    {
      id: "kernel",
      title: "KERNEL",
      image: kernel,
      description: <DescriptionKernel />,
      popupImage: kernel,
    },
  ];

  const handleImageLoad = (id) => {
    setLoadedImages((prev) => ({ ...prev, [id]: true }));
  };

  return (
    <div className=" font-poppins text-white ">
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 pl-5 pr-5 pt-24  lg:pr-20 lg:pl-20 lg:pt-52 pb-20"
      >
        {shows.map((show) => (
          <motion.div
            whileHover={{ y: -10 }}
            transition={{ duration: 0.1 }}
            key={show.id}
            className="flex flex-col gap-4 bg-[#161616] p-3  lg:p-6 rounded-xl hover:bg-[#222222] transition-all cursor-pointer  "
            onClick={() => setSelectedShow(show.id)}
          >
            {!loadedImages[show.id] && (
              <Skeleton
                className="w-full h-96 rounded-xl"
                baseColor="#2C2C2C"
              />
            )}
            <img
              className={`w-full h-full rounded-xl transition-opacity duration-500 ${
                loadedImages[show.id] ? "opacity-100" : "opacity-0"
              }`}
              src={show.image}
              alt={show.title}
              onLoad={() => handleImageLoad(show.id)}
              style={{ display: loadedImages[show.id] ? "block" : "none" }}
            />
            <h1 className="text-xl lg:text-2xl font-extralight">
              {show.title}
            </h1>
          </motion.div>
        ))}
      </motion.div>
      <AnimatePresence>
        {selectedShow && (
          <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.7 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 z-50 flex justify-center items-center transition-opacity"
          >
            <Popup
              className="relative transition "
              Titre={shows.find((show) => show.id === selectedShow).title}
              Description={
                shows.find((show) => show.id === selectedShow).description
              }
              YoutubeLink={
                shows.find((show) => show.id === selectedShow).YoutubeLink
              }
              Video={shows.find((show) => show.id === selectedShow).popupVideo}
              Image={shows.find((show) => show.id === selectedShow).popupImage}
              close={closePopup}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default SolosEtFormations;
