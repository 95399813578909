import Zone01 from "./Zone01/Zone01";
import Zone02 from "./Zone02/Zone02";

import Zone04 from "./Zone04/Zone04";
import { motion } from "framer-motion";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

function HomePage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header />
      <Zone01 />

      <Zone02 />

      <Zone04 />
      <Footer />
    </motion.div>
  );
}

export default HomePage;
