import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const linkVariants = {
    initial: { scaleX: 0 },
    hover: { scaleX: 1, transition: { duration: 0.3 } },
  };
  return (
    <motion.div
      className={`fixed z-10 flex justify-between items-center w-full pt-5 pb-5 transition-colors duration-500 font-poppins ${
        scrolled ? "bg-[#0e0e0ed3]" : "bg-transparent"
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="lg:hidden flex flex-row-reverse items-center w-full px-4    ">
        <button onClick={toggleMenu} className="text-white z-20 ">
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-[#0e0e0ed3] flex flex-col items-center justify-center transition-transform duration-500 ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden z-10`}
      >
        {[
          "Home",
          "Videos",
          "Solos_et_Creations",
          "Spectacles_et_Groupes",
          "Agenda",
          "Son",
          "Photos",
          "Bio",
          "Contact",
        ].map((item) => (
          <motion.div
            key={item}
            initial="initial"
            whileHover="hover"
            className="relative mb-4"
            whileTap={{ scale: 0.9 }}
            whileFocus={{ scale: 0.9 }}
          >
            <Link to={`/${item.replace(/_/g, "")}`} onClick={toggleMenu}>
              <p className="text-xl text-white font-light">
                {item.replace(/_/g, " ")}
              </p>
              <motion.div
                className="absolute h-[1px] w-full bg-white origin-left"
                variants={linkVariants}
              />
            </Link>
          </motion.div>
        ))}
      </div>

      <div className="hidden lg:flex lg:justify-center lg:w-full lg:gap-6 text-xl text-white font-extralight">
        <motion.div
          initial="initial"
          whileHover="hover"
          className="relative"
          whileTap={{ scale: 0.9 }}
          whileFocus={{ scale: 0.9 }}
        >
          <Link to="/">
            <p className="text-[0.9em]">Home</p>
            <motion.div
              className="absolute h-[1px] w-full bg-white origin-left"
              variants={linkVariants}
            />
          </Link>
        </motion.div>

        {[
          "Videos",
          "Solos_et_Creations",
          "Spectacles_et_Groupes",
          "Agenda",
          "Son",
          "Photos",
          "Bio",
          "Contact",
        ].map((item) => (
          <motion.div
            key={item}
            initial="initial"
            whileHover="hover"
            className="relative"
            whileTap={{ scale: 0.9 }}
            whileFocus={{ scale: 0.9 }}
          >
            <Link to={`/${item.replace(/_/g, "")}`}>
              <p className="text-[0.9em]">{item.replace(/_/g, " ")}</p>
            </Link>
            <motion.div
              className="absolute h-[1px] w-full bg-white origin-left"
              variants={linkVariants}
            />
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}

export default Header;
