import img1 from "../assets/PHOTOS/FOCH DRUMS1_img_16.jpg";
import img2 from "../assets/PHOTOS/FOCH ELEMENTS.jpg";
import img3 from "../assets/PHOTOS/FOCH TAARANG R FRANCE.jpg";
import img4 from "../assets/PHOTOS/FORET.jpeg";
import img5 from "../assets/PHOTOS/Foch Tambour.jpeg";
import img6 from "../assets/PHOTOS/GRIS K Toeplitz P Foch 2.jpg";
import img7 from "../assets/PHOTOS/Generale avec moi.jpg";
import img8 from "../assets/PHOTOS/IMG_20221115_144353.jpeg";
import img9 from "../assets/PHOTOS/IMG_6195.jpg";
import img10 from "../assets/PHOTOS/LUMIERE.jpg";
import img11 from "../assets/PHOTOS/Métal Mémoire.jpg";
import img12 from "../assets/PHOTOS/PHILIPPE FOCH.jpg";
import img13 from "../assets/PHOTOS/PLANÈTE .jpeg";
import img14 from "../assets/PHOTOS/TAARANG ONDULO.jpg";
import img15 from "../assets/PHOTOS/Tambour tête.jpg";
import img16 from "../assets/PHOTOS/canvas.png";
import img17 from "../assets/PHOTOS/mains img 13.jpg";
import img18 from "../assets/PHOTOS/plan large Phospho.jpg";
import img19 from "../assets/PHOTOS/arbre.JPG";
import img20 from "../assets/PHOTOS/arbre2.jpg";
import img21 from "../assets/PHOTOS/instrument.jpeg";

export const slides = [
  { assets: img1, width: 600, height: 1000 },
  { assets: img2, width: 800, height: 600 },
  { assets: img3, width: 1000, height: 600 },
  { assets: img4, width: 800, height: 600 },
  { assets: img5, width: 700, height: 600 },
  { assets: img6, width: 800, height: 600 },
  { assets: img7, width: 1000, height: 600 },
  { assets: img8, width: 1100, height: 600 },
  { assets: img9, width: 800, height: 600 },
  { assets: img10, width: 900, height: 600 },
  { assets: img11, width: 1200, height: 600 },
  { assets: img12, width: 800, height: 600 },
  { assets: img13, width: 800, height: 600 },
  { assets: img14, width: 800, height: 1200 },
  { assets: img15, width: 800, height: 600 },
  { assets: img16, width: 800, height: 600 },
  { assets: img17, width: 800, height: 1000 },
  { assets: img18, width: 800, height: 600 },
  { assets: img19, width: 800, height: 600 },
  { assets: img20, width: 600, height: 600 },
  { assets: img21, width: 800, height: 600 },
].map((slide) => {
  return {
    src: slide.assets,
    width: slide.width,
    height: slide.height,
  };
});

export default slides;
