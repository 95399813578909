const Description = () => {
  return (
    <div>
      <p>
        ASTER est le troisième volet d’un triptyque qui explore le lien physique
        et psychique entre la lumière et le son. De cette (con)fusion, ASTER
        dessine un chemin qui remet en jeu la question de la création et de
        l’acteur créateur. La question du geste et de l’écoute est au cœur de la
        recherche de Philippe Foch, musicien de percussions indiennes et
        asiatiques.
        <br />
        <br />
        ASTER travaille la lumière au moyen d’un jeu d’oscillations (bâtons
        phosphorescents et autres objets lumineux ou luminescents), et le son
        généré par les instruments et divers objets ou petits moteurs créant des
        vibrations. Ces systèmes sont conçus en association avec Grégory
        Joubert, artiste-inventeur du son et de la lumière. Les pièces qu’il
        fabrique sur mesure réagissent aux sons, leurs oscillations produisent
        des rythmes précis, et les petits moteurs transfigurent le solo en une
        véritable installation sidérale.
        <br />
        <br />
        Dans cette mise en vibration, un premier acte : naissance d’un monde.
        <br />
        <br />
        <br />
        PHILIPPE FOCH : CONCEPTION
        <br />
        PERCUSSIONS GREGORY JOUBERT : CONCEPTION
        <br />
        TECHNIQUE LAURE LANG : REGIE
      </p>
    </div>
  );
};

export default Description;
