const Description = () => {
  return (
    <div>
      <p>
        C’est au cours d’une résidence en Auvergne que j’ai découvert les
        phonolites (pierres sonnantes) Au fil de plusieurs résidences , j’ai
        constitué un « lithophone » un ensemble de pierres avec des hauteurs
        aléatoires , parfois tempérées , (accordées sur des notes précises)
        parfois non tempérées , ce qui permet de jouer sur des micro tonalités
        Ces pierres viennent de la lave des volcans qui s’est éteinte il y a
        bien longtemps…
        <br />
        <br />
        « C’est la peut-être la maison ou j’ai vécu quand je n’existais pas ,
        quand la terre n’existait pas quand tout n’était que lune ou pierre ou
        ombre quand la lumière immobile demeurait sans naitre Peut être en ce
        temps là cette pierre était elle ma maison, ma fenêtre, était elle mes
        yeux .. »
        <br /> <br />
        Pablo Neruda
        <br />
        <br />
        <br />
        PHILIPPE FOCH : LITHOPHONE , ELECTRONIQUE
      </p>
    </div>
  );
};

export default Description;
