const Description = () => {
  return (
    <div>
      <p>
        COMME UN RITUEL A PARTAGER
        <br />
        <br />
        On est invité à s’assoir tout près du musicien lui-même assis à même le
        sol ; devant lui, et face à nous, quelques instruments de percussions,
        simple instrumentarium composé de tablas, gongs, métaux...
        <br />
        <br />
        De ces objets qui ne pourraient être au commencement que forme et
        matière, le musicien explore chaque sonorité. De son essence même
        jusqu’à sa circulation et sa disparition dans l’espace, de la matière
        dont il émane jusqu’à atteindre le corps de l’autre, chaque son est
        donné à écouter, chaque toucher, chaque geste est donné à ressentir.
        <br />
        <br />
        <br />
        PHILIPPE FOCH : PERCUSSIONS
        <br />
        BRIGITTE LALLIER MAISONNEUVE : REGARD EXTERIEUR
      </p>
    </div>
  );
};

export default Description;
