import YouTube from "@u-wave/react-youtube";
import Vimeo from "@u-wave/react-vimeo";

const popup = ({ Titre, Description, close, Video, YoutubeLink }) => {
  return (
    <div className=" relative flex flex-col 2xl:flex-row 2xl:justify-between bg-[#222222] h-full 2xl:h-auto w-full 2xl:w-5/6 p-5  lg:p-20 lg:gap-12 rounded-lg 2xl:overflow-hidden overflow-auto  ">
      <button
        className=" absolute top-0 right-0 lg:pt-5 lg:pr-5 pr-3 pt-3"
        onClick={close}
      >
        <svg viewBox="0 0 21 21" fill="currentColor" height="2em" width="2em">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M15.5 15.5l-10-10zM15.5 5.5l-10 10" />
          </g>
        </svg>
      </button>

      <div className=" flex flex-col gap-6 font-extralight">
        <h1 className=" text-4xl lg:text-4xl ">{Titre}</h1>
        <p className="w-full text-base xl:text-lg ">{Description}</p>
      </div>
      {YoutubeLink ? (
        <YouTube className=" lg:w-full" video={YoutubeLink} controls />
      ) : (
        Video && <Vimeo video={Video} className=" lg:w-full" responsive />
      )}
    </div>
  );
};

export default popup;
