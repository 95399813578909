const Description = () => {
  return (
    <div>
      <p>
        Une invitation à découvrir, entre l’ici et l’ailleurs, le taarang,
        ensemble traditionnel de tablas très peu utilisé aujourd’hui et revisité
        par Philippe Foch qui mêle à la technique traditionnelle, le jeu griffé,
        frotté et les sons électroniques. <br /> Dans une très grande proximité
        avec son public, il donne à saisir le geste musical et à découvrir
        l’objet, sa forme, sa matière et son pouvoir d’évocation pour un moment
        suspendu d’écoute et de contemplation de la matière et de la lumière.
        Fugit est un voyage dans le temps et l’espace.
        <br />
        <br />
        <br />
        PHILIPPE FOCH : PERCUSSIONS , ELECTRONIQUE
      </p>
    </div>
  );
};

export default Description;
