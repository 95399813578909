const Description = () => {
  return (
    <div>
      <p>
        Sur le plateau, des plaques et lames de métal (cuivre, étain, acier...)
        sont suspendues verticalement, formant un clavier allant de la plus fine
        (0,1 mm) à la plus épaisse. Philippe Foch a conçu cet ensemble comme un
        véritable instrument. Il peut se jouer en brassant l’air sans toucher la
        matière, avec des plumes, des balais africains, les doigts, ou encore
        des mailloches sur le métal le plus épais.
        <br />
        <br />
        Ces lames et plaques servent également de support pour la projection
        d’un film artisanal. Ce film, composé d’éléments naturels, crée un
        théâtre des rêves, éclairant le plateau et transformant les plaques en
        tableaux vivants.
        <br />
        <br />
        <br />
        <br />
        PHILIPPE FOCH : CONCEPTION SON ET MATERIAUX FILM , ENSEMBLE DE MÉTAL{" "}
        <br />
        MAXIME LANCE : REALISATION ET REGIE SON STEPHANE BORDONARO : REALISATION
        ET REGI VIDEO{" "}
      </p>
    </div>
  );
};

export default Description;
